<template>
	<div class="page">
		<div class="box-bg"/>

		<div class="content">
			<div class="txt_remind ">
				<a  href="../showVideo.html">视频教程</a>
			</div>
			<div v-if="robot.online === 0"
			     class="mt-4 flex column">
				<div class="relative">
					<img v-if="encode"
					     :src="encode"
					     class="encode">
					<img v-if="!encode"
					     src="../../assets/template.png"
					     class="encode">
					<div v-if="!encode && !second"
					     class="login_avatar_txt flex h-center">
						正在加载...
					</div>
					<div v-if="!encode && second"
					     class="login_avatar_txt flex h-center">
						正在登录...
					</div>
					<div v-if="!encode"
					     class="loadEffect">
						<span/>
						<span/>
						<span/>
						<span/>
						<span/>
						<span/>
						<span/>
						<span/>
					</div>
				</div>
				<div class="fs-12 color-gray mt-1">
					用需要登录的微信账号扫描上方二维码
				</div>
				<div class="text-center mt-1">{{ userInfo.userCode }}</div>
				<div class="color-primary"
				     v-if="haveCode==2">微信授权二维码
				</div>
				<div class="color-primary"
				     v-if="haveCode==2">授权码和登录码需要同一个微信号扫码方可
				</div>
				<div class="color-blue"
				     v-if="haveCode==3">微信登录二维码
				</div>
				<!-- <div class="color-blue"
						 v-if="haveCode==3">请用刚扫授权的微信号扫此二维码登录</div>
				<div class="color-blue"
						 v-if="haveCode==3">若是其他微信号扫码会登录失败严重者导致封号</div> -->
				<div class="mt-1 fs-12 color-warning"
				     v-if="haveCode==3">（长按识别无效）
				</div>
				<button class="login_share button flex h-center" @click="handleShare">
					<van-icon name="wechat" size="24" class="mr-1"/>
					发送给附近好友
				</button>
				<button class="mt-4 reflesh"
				        @click="refreshs()">刷新二维码
				</button>
			</div>

			<div v-if="robot.online === 2"
			     class="mt-30 flex column">
				<img :src="robot.headImgUrl"
				     class="avatar">
				<div class="color-danger fs-12 b mt-2">{{ robot.nickName }}</div>
			</div>

			<div class="dirver mt-6"/>
			<div class="login-guide">
				<div class="login-guide__item mb-2">
					<div class="login-guide__item--header">1.截图保存二维码，发送到其他手机/电脑/ipad</div>
					<div class="ml-2">
						<img src="./assets/login_guide1.png"/>
					</div>
				</div>
				<div class="login-guide__item">
					<div class="login-guide__item--header">2.使用微信【扫一扫】扫码登录即可</div>
					<div class="ml-2">
						<img src="./assets/login_guide2.png"/>
					</div>
				</div>
			</div>
		</div>
		<RiskAvoidanceGuide class="mt-4 mb-4"/>

		<van-popup v-model:show="show"
		           style="width: 75%"
		           class="flex column pl-3 pr-3">
			<div class="fs-14 b mt-4">如何让机器人更加稳定，不容易掉线？</div>
			<div class="fs-12 mt-3 color-gray">1.用户不要主动关闭微信登陆（比如关闭网页或者windows登陆）；</div>
			<div class="fs-12 mt-1 color-gray">2.用户不要随意更换登陆设备（比如从原先使用的手机换到另外一个手机上登陆）；</div>
			<div class="fs-12 mt-1 color-gray">3.用户应避免在使用中的同时扫码登陆到其他PC端（比如扫码登陆到其他平板类设备）；</div>
			<div class="fs-12 mt-1 color-gray">4.用户应避免随意频繁的更改微信密码（比如手机端收到官方提醒 您微信存在环境异常，请确认是否本人登陆，为了安全，请修改登陆密码之类的）；</div>
			<div class="fs-12 mt-1 color-gray">5.在初始登陆的24小时内，避免频繁操作，如需发送群消息或者个友信息，间隔时间最好为5-8分钟</div>
			<div class="fs-12 mt-1 color-primary b">重点：商家在进行了以上第2点、第3点后，之后的一个周期（8天）内都有一定几率会被官方踢下线！！！</div>
			<button class="mt-4 mb-4 reflesh b"
			        @click="show=false">我知道了
			</button>
		</van-popup>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Dialog, Toast } from 'vant'
import { defineAsyncComponent } from 'vue'

export default {
	name: 'backUpLogin',
	components: {
		RiskAvoidanceGuide: defineAsyncComponent(() => import('../../components/RiskAvoidanceGuide'))
	},
	data() {
		return {
			loading: false,
			robot: {
				online: 0
			},
			robotValue: '',
			soNumber: '',

			loginType: 0,
			inviteCode: '',
			encode: '',
			haveCode: 1,
			timer: '',

			timeControls: '',
			show: false,
			second: false,
			toast1: ''
		}
	},
	computed: {
		...mapGetters(['userInfo'])
	},
	created() {
		this.initData()
	},
	unmounted() {
		let _this = this
		clearInterval(_this.timeControls)
	},
	methods: {
		async initData() {
			const _this = this
			await _this.getData()
			await _this.loginWx()
		},
		getData() {
			const _this = this
			return new Promise((resolve, reject) => {
				_this
						.$post('/WxRobot/GetPorts', { wxType: 1 })
						.then((res) => {
							if (res) {
								_this.robot = res[0]
								_this.robot.online = 0
							}
							resolve()
						})
						.catch((e) => {
							if (e.message == '未包含TokenKey') {
								// _this.$route.go(-1)
								_this.$router.go(-1)
							}
							reject()
						})
			})
		},
		async loginWx() {
			const _this = this
			// console.log(_this.robot.deviceId)
			await _this
					.$post('/WxRobot/WxRobotLogin', {
						deviceId: _this.robot.deviceId,
						robotWxId: _this.robot.robotWxId,
						expireDate: _this.robot.expireDate,
						clientIp: '',
						robotWxType: _this.robot.robotWxType,
						loginType: _this.loginType,
						inviteCode: _this.inviteCode
					})
					.then((res) => {
						_this.soNumber = res
					})
			// _this.getEncode()
			_this.timeControl()
		},
		getEncode() {
			const _this = this
			return new Promise((resolve, reject) => {
				_this.$get('/WxRobot/GetWxLoginResult', {
					     soNumber: _this.soNumber
				     })
				     .then((res) => {
					     let content = {}
					     let msg = ''
					     if (res) {
						     if (res && res.state) {
							     content = res
							     switch (res.state) {
								     case '-1':
									     clearInterval(_this.timeControls)
									     _this.$Dialog.confirm({
										          title: '二维码已失效',
										          message: content.logoutReason
									          })
									          .then(() => {
										          _this.encode = ''
										          _this.initData()
									          })
									          .catch(() => {
										          // on cancel
									          })
									     localStorage.removeItem('haveCode')
									     break
								     case '0':
									     msg = '获取二维码失败'
									     break
								     case '1':
									     msg = ''
									     _this.$store.dispatch('setLoginUrl', content.qrUrl)
									     _this.haveCode = content.qrType == 1 ? 3 : 2
									     _this.encode = content.qrUrl

									     break
								     case '2':
									     _this.robot.wxNick = content.wxNick
									     Dialog.alert({
										           title: '提示',
										           message: '登录成功'
									           })
									           .then(() => {
										           // on close
									           })
									     clearInterval(_this.timeControls)
									     _this.$router.replace('/index')
									     break
								     case '3':
									     msg = '登录异常：' + content.logoutReason
									     break
								     case '5':
									     msg = ''
									     _this.encode = ''
									     _this.second = true
									     break
								     case '6':
									     msg = '登录失败'
									     break
								     default:
							     }
							     if (msg) {
								     clearInterval(_this.timeControls)
								     Dialog.alert({
									           title: '提示',
									           message: msg
								           })
								           .then(() => {
									           // on close
								           })
							     }
						     }
					     }
					     resolve()
				     })
				     .catch((e) => {
					     // _this.timeControl()
					     reject()
				     })
			})

		},
		handleShare() {
			const bean = {
				imgUrl: this.encode
			}
			window.shareImg.postMessage(
					JSON.stringify(bean)
			)
		},
		timeControl() {
			const _this = this
			_this.getEncode()
			clearInterval(_this.timeControls)
			_this.timeControls = setInterval(async () => {
				await _this.getEncode()
			}, 2000)
		},
		refreshs() {
			const _this = this
			_this.encode = ''
			_this.$Toast('刷新成功！')
			_this.initData()
			// _this.initData();
		}
	}
}
</script>

<style scoped lang="scss">
.login_share {
	margin-top: 70px;
	color: white;
	width: 188px;
	height: 40px;
	background: #06C15F;
	border-radius: 4px 4px 4px 4px;
}

.box-bg {
	height: 281px;
	background: url('./assets/bg.png') no-repeat;
	background-size: contain;
}

.risk-avoidance-guide {
	position: absolute;
	bottom: px(20);
	left: 0;
	right: 0;
}

.login-guide {
	padding: 15px 15px 20px;
}

.login-guide__item {
	font-weight: bold;

	.login-guide__item--header {
		margin-bottom: 10px;
	}

	.login-guide__item--content {
		//margin-left: 10px;
	}
}

.page {
	position: relative;
	overflow-y: auto;

	.box {
		width: 100%;
		height: px(280);
	}

	.avatar {
		width: px(160);
		height: px(160);
		background: rgba(0, 0, 0, 0.4);
		border-radius: px(80);
		border: px(4) solid #FF445E;
	}

	.color-blue {
		color: #3784F8;
	}

	.txt_remind {
		padding-top: 10px;
		color: #3784F8;
		text-align: right;
		margin-right: px(15);
		text-decoration: underline;
	}

	.content {
		margin-top: -248px;
		margin-left: 20px;
		margin-right: 20px;
		/*    position: absolute;
				top: px(33);

				left: 5%;
				width: 90%;*/
		background: linear-gradient(180deg, #EBF0FE 0%, #FFFFFF 100%);
		border-radius: px(10);
		z-index: 1;
	}

	.encode {
		width: px(160);
		height: px(160);
	}

	.reflesh {
		padding: px(6) px(35);
		font-size: px(14);
		font-weight: 500;
		color: #FF445E;
		border-radius: px(2);
		border: px(1) solid #FF445E;
	}

	.color-blue {
		color: #3784F8;
	}

	.dirver {
		width: 100%;
		height: px(1);
		border-bottom: px(1) dashed #FF445E;
	}

	.loadEffect {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		width: 100px;
		height: 100px;
	}

	.loadEffect span {
		display: inline-block;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #48a5f4;
		position: absolute;
		// animation: load 1.04s ease infinite;
		-webkit-animation: load 1.04s ease infinite;
	}

	@-webkit-keyframes load {
		0% {
			opacity: 1;
			background-color: #000 !important;
		}

		100% {
			opacity: 0.2;
			background-color: #000 !important;
		}
	}

	.loadEffect span:nth-child(1) {
		left: 0;
		top: 50%;
		margin-top: px(-8);
		-webkit-animation-delay: 0.13s;
	}

	.loadEffect span:nth-child(2) {
		left: px(14);
		top: px(14);
		-webkit-animation-delay: 0.26s;
	}

	.loadEffect span:nth-child(3) {
		left: 50%;
		top: 0;
		margin-left: px(-8);
		-webkit-animation-delay: 0.39s;
	}

	.loadEffect span:nth-child(4) {
		top: px(14);
		right: px(14);
		-webkit-animation-delay: 0.52s;
	}

	.loadEffect span:nth-child(5) {
		right: 0;
		top: 50%;
		margin-top: px(-8);
		-webkit-animation-delay: 0.65s;
	}

	.loadEffect span:nth-child(6) {
		right: px(14);
		bottom: px(14);
		-webkit-animation-delay: 0.78s;
	}

	.loadEffect span:nth-child(7) {
		bottom: 0;
		left: 50%;
		margin-left: px(-8);
		-webkit-animation-delay: 0.91s;
	}

	.loadEffect span:nth-child(8) {
		bottom: px(14);
		left: px(14);
		-webkit-animation-delay: 1.04s;
	}

	.login_avatar_txt {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: px(160);
		height: px(160);
		font-size: px(16);
		color: #fff;
		border-radius: 50%;
		background-color: rgba(0, 0, 0, 0.3);
	}

	.ml-15 {
		margin-left: px(15);
	}

	.mr-15 {
		margin-right: px(15);
	}

	.mt-15 {
		margin-top: px(15);
	}

	.mt-30 {
		margin-top: px(30);
	}

	.mb-30 {
		margin-bottom: px(30);
	}
}
</style>
